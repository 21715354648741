import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('REACT_APP_API_URL');
const baseUrl = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-orders/v3` : undefined;

export const OrderService = {
  orderInfoAdmin: baseUrl ? `${baseUrl}/orders/info` : null,
  orderInfo: baseUrl ? `${baseUrl}/orders/info/own` : null,
  ordersFilters: baseUrl ? `${baseUrl}/orders/filters/own` : null,
  ordersFiltersForAdmin: baseUrl ? `${baseUrl}/orders/filters` : null,
  orderDetails: baseUrl ? `${baseUrl}/orders` : null,
  ordersCreation: baseUrl ? `${baseUrl}/orders/create` : null,
};
