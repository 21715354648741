import { ContentText, fontWeights, fontSizes, ChipItem } from '@fortum/elemental-ui';
import { FiltersLayout } from '../filters/Filters';
import { Container, FirstRow } from './styles';
import { ContractsFiltersContext } from '@contexts/ContractsFiltersContext';
import { forwardRef, useCallback, useContext, useMemo } from 'react';
import { compact } from 'lodash';
import { FiltersAccordion } from '@components/FiltersAccordion';
import { PAGE_HEADER_TEST_ID } from '@common/testIds';

interface PageHeaderProps {
  pageName: string;
}

type FilterType = 'equipment' | 'waste' | 'search';

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(({ pageName }, ref) => {
  const {
    selectedEquipmentTypes,
    selectedWasteTypes,
    search,
    handleEquipmentTypesChange,
    handleWasteTypesChange,
    handleSearchChange,
    resetAll,
  } = useContext(ContractsFiltersContext);

  const chipsItems = useMemo<(ChipItem<string> & { filterType: FilterType })[]>(() => {
    const toChipItem = (rawItem: { key: string; value: string; type: FilterType }) => ({
      label: rawItem.value,
      value: rawItem.key,
      filterType: rawItem.type,
    });

    return compact([
      ...selectedWasteTypes.map(wasteType => toChipItem({ ...wasteType, type: 'waste' })),
      ...selectedEquipmentTypes.map(equipmentType =>
        toChipItem({ ...equipmentType, type: 'equipment' }),
      ),
      search.trim().length > 0
        ? { label: search, value: 'search', filterType: 'search' }
        : undefined,
    ]);
  }, [selectedEquipmentTypes, selectedWasteTypes, search.trim().length]);

  const removeFilter = useCallback(
    (
      item: ChipItem<string> & {
        filterType: FilterType;
      },
    ) => {
      if (item.filterType === 'search') {
        handleSearchChange('');
      } else if (item.filterType === 'waste') {
        const newWaste = selectedWasteTypes
          .filter(wasteType => wasteType.value !== item.label)
          .map(wasteType => wasteType.key);
        handleWasteTypesChange(newWaste);
      } else {
        const newEq = selectedEquipmentTypes
          .filter(equipmentType => equipmentType.value !== item.label)
          .map(equipmentType => equipmentType.key);
        handleEquipmentTypesChange(newEq);
      }
    },
    [selectedWasteTypes, selectedEquipmentTypes],
  );

  return (
    <Container ref={ref} data-testid={PAGE_HEADER_TEST_ID}>
      <FirstRow>
        <ContentText fontWeight={fontWeights.regular} fontSize={fontSizes.xl}>
          {pageName}
        </ContentText>
        <FiltersLayout />
      </FirstRow>
      {chipsItems.length > 0 && (
        <FiltersAccordion
          chipsItems={chipsItems}
          removeFilter={removeFilter}
          clearAllFilters={resetAll}
        />
      )}
    </Container>
  );
});

PageHeader.displayName = 'PageHeader';
