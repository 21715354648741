import { staticBorderStyles, verticalContainer } from '@components/styles';
import {
  BoxProps,
  breakpoints,
  colors,
  ContentTextProps,
  fontSizes,
  IconProps,
  spacing,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const TileContainer = styled.div`
  ${verticalContainer};
  ${staticBorderStyles()};

  height: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 48px ${spacing.l} 0;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    padding: ${spacing.xs};
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0px;
  justify-content: center;
  align-items: center;
`;

export const headerTextStyles: ContentTextProps = {
  fontSize: fontSizes.xl,
  textAlign: 'center',
};

export const headerContainerStyles: BoxProps = {
  display: 'flex',
  gap: spacing.xxxs,
  alignItems: 'center',
};
export const messageTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  textAlign: 'center',
  mb: spacing.xxs,
  minHeight: '72px',
};

export const iconStyles: IconProps = {
  size: 24,
  color: colors.oceanGreen,
};
