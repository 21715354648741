import {
  RowProps,
  Row,
  Col,
  spacing,
  IconFiles,
  IconBin,
  IconTruckOutline,
} from '@fortum/elemental-ui';
import { paths } from '@config/routes';
import { useTranslation } from 'react-i18next';
import i18n, { Namespace } from '@config/i18n';
import { COL_HEIGHT } from '@routes/home/styles';
import { FC, ReactNode, useMemo } from 'react';
import { Tile } from './Tile';
import { ordersCreationToggleOn } from '@utils/featureToggles';

interface ColumnProps {
  children: ReactNode;
  addMarginBottom?: boolean;
}

const Column: FC<ColumnProps> = ({ children, addMarginBottom = false }) => (
  <Col
    xl={4}
    height={{ xl: COL_HEIGHT, xs: 'fit-content' }}
    maxHeight={COL_HEIGHT}
    mb={addMarginBottom ? { xl: 0, xs: spacing.xxs } : undefined}
  >
    {children}
  </Col>
);

//TODO: fix links position on Orders Tile when order creation is available
export const QuickAccessSection: FC<RowProps> = props => {
  const { t } = useTranslation<Namespace[]>(['reports', 'home']);

  const ordersAccessLinks = useMemo(() => {
    const ordersOverviewLinkProps = {
      id: 'orders-forward-link',
      description: t('home:quickAccess.orders.linkDesc'),
      path: paths.orders,
    };

    return ordersCreationToggleOn
      ? [
          ordersOverviewLinkProps,
          {
            id: 'order--creation-forward-link',
            description: t('orderCreation:createNewOrder'),
            path: paths.ordersCreation,
          },
        ]
      : ordersOverviewLinkProps;
  }, [i18n.language]);

  return (
    <Row {...props}>
      <Column addMarginBottom>
        {ordersCreationToggleOn ? (
          <Tile
            icon={<IconTruckOutline />}
            header={t('home:quickAccess.orders.headerIfOrderCreationIsAllowed')}
            description={t('home:quickAccess.orders.descIfOrderCreationIsAllowed')}
            links={ordersAccessLinks}
          />
        ) : (
          <Tile
            icon={<IconTruckOutline />}
            header={t('home:quickAccess.orders.headerIfOrderCreationIsDisabled')}
            description={t('home:quickAccess.orders.descIfOrderCreationIsDisabled')}
            links={ordersAccessLinks}
          />
        )}
      </Column>
      <Column addMarginBottom>
        <Tile
          icon={<IconBin />}
          header={t('home:quickAccess.wasteServices.header')}
          description={t('home:quickAccess.wasteServices.desc')}
          links={{
            id: 'services-forward-link',
            description: t('home:quickAccess.wasteServices.linkDesc'),
            path: paths.services,
          }}
        />
      </Column>

      <Column>
        <Tile
          icon={<IconFiles />}
          header={t('home:quickAccess.reports.header')}
          description={t('home:quickAccess.reports.desc')}
          links={[
            {
              id: 'dashboard-forward-link',
              description: t('reports:dashboard.navigation'),
              path: paths.dashboardReport,
            },
            {
              id: 'waste-details-report-forward-link',
              description: t('reports:wasteQty.navigation'),
              path: paths.wasteDetailsReport,
            },
            {
              id: 'cost-details-report-forward-link',
              description: t('reports:cost.navigation'),
              path: paths.costDetailsReport,
            },
          ]}
        />
      </Column>
    </Row>
  );
};
