import { FC, useCallback, useContext, useRef, useState } from 'react';
import { ButtonsContainer, Container, plainButtonStyles } from './styles';
import { OrderTileHeader } from './OrderTileHeader';
import { OrderCreationForm } from './OrderCreationForm';
import { Button, IconChevronDown, IconChevronUp, useToggle } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { OrderDetailsForm } from '../OrderDetailsForm';
import { Testable } from '@components/types';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';
import { useClickOutside } from '@hooks/useClickOutside';
import { ConfirmationModal } from '@routes/ordersCreation/components';

interface OrderTileProps extends Testable {
  orderIndex: number;
  deletionAllowed: boolean;
  deleteOrder: (contractIdentifier: string, formIdentifier: string) => void;
}

export const OrderTile: FC<OrderTileProps> = ({
  orderIndex,
  deleteOrder,
  deletionAllowed,
  'data-testid': dataTestId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation<Namespace>('common');
  const { validate, missingRequiredFields, contractIdentifier, form } = useContext(
    SingleOrderCreationContext,
  );
  const [discardOrderModalOpen, toggleDiscardOrderModalOpen] = useToggle();

  const [detailsOpen, setDetailsOpen] = useState(false);

  const toggleDetailsOpen = useCallback(() => {
    setDetailsOpen(prev => !prev);
  }, []);

  useClickOutside(containerRef, validate);

  return (
    <Container
      data-testid={dataTestId}
      ref={containerRef}
      $error={missingRequiredFields.length > 0}
    >
      <OrderTileHeader
        detailsOpen={detailsOpen}
        orderIndex={orderIndex}
        onClick={toggleDetailsOpen}
      />

      <OrderCreationForm />

      {detailsOpen && <OrderDetailsForm />}

      <ButtonsContainer>
        <Button
          data-testid="show-details-button"
          {...plainButtonStyles}
          rightIcon={detailsOpen ? <IconChevronUp /> : <IconChevronDown />}
          onClick={toggleDetailsOpen}
        >
          {detailsOpen ? t('hideDetails') : t('showDetails')}
        </Button>

        {deletionAllowed && (
          <Button
            status="plain"
            onClick={toggleDiscardOrderModalOpen}
            data-testid="discard-orders-button"
          >
            {t('common:discard')}
          </Button>
        )}
      </ButtonsContainer>

      <ConfirmationModal
        data-testid="discard-order-modal"
        headerText={t('common:delete')}
        messageText={t('orderCreation:deleteOrderConfirmationMessage')}
        opened={discardOrderModalOpen}
        onClose={toggleDiscardOrderModalOpen}
        onConfirmClick={() => deleteOrder(contractIdentifier, form.formIdentifier)}
      />
    </Container>
  );
};
