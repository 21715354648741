import { QlikSelectionObject, QlikObject } from '@components/qlik';
import { FC } from 'react';
import {
  VerticalContainerWithSmallGaps,
  VerticalContainerWithBigGaps,
  DynamicTableContainer,
} from './styles';
import { DashboardReportObjects } from '@config/qlik';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { ExportDataHandler } from '../types';
import { ExportButtonPositionedRight } from '../styles';

interface TableProps {
  objectIds: DashboardReportObjects;
  exportDataHandler: ExportDataHandler;
}

export const Table: FC<TableProps> = ({ objectIds, exportDataHandler }) => {
  const { t } = useTranslation<Namespace>('common');
  return (
    <VerticalContainerWithBigGaps>
      <ExportButtonPositionedRight
        exportDataHandler={exportDataHandler}
        text={t('common:export')}
      />
      <DynamicTableContainer>
        <VerticalContainerWithSmallGaps>
          <QlikSelectionObject
            id={objectIds.dynamicTable.dimensions.company}
            dataTestId="dimension-company"
          />
          <QlikSelectionObject
            id={objectIds.dynamicTable.dimensions.wasteDetails}
            dataTestId="dimension-waste-details"
          />
          <QlikSelectionObject
            id={objectIds.dynamicTable.dimensions.measures}
            dataTestId="dimensions-measures"
          />
        </VerticalContainerWithSmallGaps>

        <QlikObject id={objectIds.dynamicTable.table} height="500px" dataTestId="table" />
      </DynamicTableContainer>
    </VerticalContainerWithBigGaps>
  );
};
