import { Auth0ContextInterface, LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { sessionKeys, StorageUtils } from '@common/storage';
import { clearAuthenticatedUser } from '@config/azureInsights';
import { paths } from '@config/routes';
import { terminateApplicationSession } from '@data/api/auth';
import { areAuthTokensAvailable } from '@utils/auth';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type UseAuthProvider = Pick<Auth0ContextInterface, 'isAuthenticated' | 'isLoading'> & {
  logout: () => Promise<void>;
  storeTokens: () => Promise<void>;
};

const logoutOptions: LogoutOptions = { logoutParams: { returnTo: window.location.origin } };

export const useAuthProvider = (): UseAuthProvider => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const navigate = useNavigate();

  const areTokensStored = areAuthTokensAvailable();

  const storeTokens = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();

    if (idTokenClaims) StorageUtils.putItem(sessionKeys.idToken, idTokenClaims.__raw);

    StorageUtils.putItem(sessionKeys.accessToken, accessToken);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    const redirectUriNotStoredOrEqualToMain =
      !StorageUtils.hasItem(sessionKeys.redirectUri) ||
      StorageUtils.getItem(sessionKeys.redirectUri) === paths.main;

    if (
      redirectUriNotStoredOrEqualToMain &&
      !location.pathname.includes(paths.auth) &&
      !location.pathname.includes(paths.selectCustomer)
    ) {
      const redirectUri = `${location.pathname}${location.search ?? ''}`;
      sessionStorage.setItem(StorageUtils.sessionKeys.redirectUri, redirectUri);
    }

    if (isLoading) return;

    if (!isAuthenticated) loginWithRedirect();
    /**
     * TODO: Consider merging this functionality with the one in the Auth callback page.
     */ else if (!areTokensStored) {
      storeTokens();

      if (window.location.pathname !== paths.auth) {
        StorageUtils.putItem(sessionKeys.redirectUri, window.location.pathname);

        navigate(0);
      }
    }
  }, [isLoading, isAuthenticated, areTokensStored]);

  const handleLogout = useCallback(async () => {
    await terminateApplicationSession();

    StorageUtils.removeItem(sessionKeys.accessToken);
    StorageUtils.removeItem(sessionKeys.idToken);
    StorageUtils.removeItem(sessionKeys.redirectUri);

    clearAuthenticatedUser();

    // terminate the Auth0 session
    logout(logoutOptions);
  }, []);

  return {
    logout: handleLogout,
    storeTokens,
    isAuthenticated,
    isLoading,
  };
};
