import { paths } from '@config/routes';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmationModal,
  NavigationHeaderWithMarginBottom,
  OrdersCreationContext,
} from '../components';
import { SummaryTable } from './components/SummaryTable/SummaryTable';
import { Container, ButtonsContainer } from './components/styles';
import { CreationDetails } from './components/CreationDetails';
import { Button, Checkbox, ContentText, ErrorMessage, useToggle } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { VerticalContainer } from '@components/styles';
import { discardButtonStyles, backButtonStyles, primaryButtonStyles } from './components/styles';
import { SummaryPreviewHeader } from './components/SummaryPreviewHeader/SummaryPreviewHeader';
import { SummaryHeader } from './SummaryHeader/SummaryHeader';
import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';
import { CreationForms } from 'src/types/ordersCreation';
import { Notification, useNotification } from '@components/Notification';
import { useOrdersCreationMutation } from '@data/hooks/useOrdersCreationMutation';

interface SummaryProps {
  creationForms: CreationForms;
  goToOrdersCreation: () => void;
}

interface SummaryPreviewProps extends SummaryProps {
  submitOrders: () => void;
}
const SummaryPreview: FC<SummaryPreviewProps> = ({
  creationForms,
  submitOrders,
  goToOrdersCreation,
}) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation<Namespace[]>(['common', 'orderCreation']);

  const headerLinkProps = useMemo<PageNavigationHeaderProps['link']>(
    () => ({
      id: 'navigate-to-home-link',
      description: t('orderCreation:returnToPreviousPage'),
      onClick: () => navigate(-1),
    }),
    [i18n.language],
  );

  const checkBoxMessage = `* ${t('orderCreation:summaryPage.confirmationCheckbox.label')}`;

  const [modalOpen, toggleModalOpen] = useToggle(false);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [displayCheckboxError, setDisplayCheckboxError] = useState(false);

  const toggleCheckbox = useCallback(
    () =>
      setCheckboxChecked(prev => {
        if (!prev) setDisplayCheckboxError(false);

        return !prev;
      }),
    [],
  );

  const navigateBack = useCallback(() => navigate(-1), []);

  const submit = useCallback(() => {
    if (checkboxChecked) {
      submitOrders();
    } else setDisplayCheckboxError(true);
  }, [checkboxChecked, submitOrders]);

  return (
    <>
      <NavigationHeaderWithMarginBottom
        link={headerLinkProps}
        title={t('orderCreation:pageHeader')}
      />
      <Container>
        <SummaryPreviewHeader />
        <CreationDetails />

        <SummaryTable creationForms={creationForms} />

        <VerticalContainer>
          <Checkbox name="confirmVerification" label={checkBoxMessage} onClick={toggleCheckbox} />
          <ErrorMessage active={displayCheckboxError}>
            <ContentText>{t('orderCreation:summaryPage.checkboxError')}</ContentText>
          </ErrorMessage>
        </VerticalContainer>

        <ButtonsContainer>
          <Button
            data-testid="discard-creation-button"
            {...discardButtonStyles}
            onClick={toggleModalOpen}
          >
            {t('common:discard')}
          </Button>
          <Button data-testid="back-to-forms-button" {...backButtonStyles} onClick={navigateBack}>
            {t('common:back')}
          </Button>
          <Button data-testid="submit-creation-button" {...primaryButtonStyles} onClick={submit}>
            {t('common:send')}
          </Button>
        </ButtonsContainer>
      </Container>

      <ConfirmationModal
        headerText={t('common:delete')}
        messageText={t('orderCreation:summaryPage.discardModal.message')}
        opened={modalOpen}
        onClose={toggleModalOpen}
        onConfirmClick={goToOrdersCreation}
      />
    </>
  );
};

const Summary: FC<SummaryProps> = ({ creationForms, goToOrdersCreation }) => {
  const navigate = useNavigate();

  const { t } = useTranslation<Namespace[]>(['common', 'orderCreation']);

  const navigateToOrdersOverview = useCallback(() => navigate(paths.orders), []);

  return (
    <Container>
      <SummaryHeader onClick={goToOrdersCreation} />

      <CreationDetails />

      <SummaryTable creationForms={creationForms} />

      <ButtonsContainer>
        <Button
          data-testid="navigate-to-orders-button"
          {...primaryButtonStyles}
          onClick={navigateToOrdersOverview}
        >
          {t('orderCreation:summaryPage.goToOrdersOverview')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export const SummaryPage: FC = () => {
  const { displayNotification, setDisplayErrorNotification, closeNotification } = useNotification();
  const navigate = useNavigate();

  const { creationForms, clearForms } = useContext(OrdersCreationContext);
  const [preview, togglePreview] = useToggle(true);

  const [cachedCreationForms] = useState<CreationForms>(creationForms);

  const goToOrdersCreation = useCallback(() => {
    clearForms();
    navigate(paths.ordersCreation);
  }, []);

  useEffect(() => {
    if (creationForms.length === 0) navigate(paths.ordersCreation);
  }, []);

  const ordersCreationMutation = useOrdersCreationMutation({
    onSuccess: ({ failedOrders, forbiddenOrders }) =>
      (failedOrders.length > 0 || forbiddenOrders.length > 0) && setDisplayErrorNotification(),
    onError: setDisplayErrorNotification,
    onSettled: () => {
      togglePreview();
      clearForms();
    },
  });

  const createOrders = () => ordersCreationMutation.mutate(creationForms);

  return (
    <>
      {preview ? (
        <SummaryPreview
          creationForms={creationForms}
          goToOrdersCreation={goToOrdersCreation}
          submitOrders={createOrders}
        />
      ) : (
        <Summary creationForms={cachedCreationForms} goToOrdersCreation={goToOrdersCreation} />
      )}

      <Notification
        type="error"
        message="Failed to create some orders"
        opened={displayNotification === 'error'}
        onClose={closeNotification}
        maxWidth="30rem"
      />
    </>
  );
};
