import React from 'react';
import {
  Box,
  colors,
  ContentText,
  fontSizes,
  fontWeights,
  IconArrowLeft,
} from '@fortum/elemental-ui';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { paths } from '@config/routes';
import { StyledHomeLink } from '@components/styles';
import { PAGE_HEADER_TEST_ID } from '@common/testIds';

export const PageHeader = () => {
  const { t } = useTranslation<Namespace>('user');

  return (
    <Box padding={'16px 78px'} data-testid={PAGE_HEADER_TEST_ID}>
      <StyledHomeLink
        noUnderline
        color={colors.oceanGreen}
        display="flex"
        alignItems="center"
        tabIndex={0}
        href={paths.main}
        fontSize={fontSizes.xs}
        fontWeight={fontWeights.medium}
      >
        <IconArrowLeft />
        {t('header.link')}
      </StyledHomeLink>
      <ContentText fontSize={fontSizes.xl}>{t('header.title')}</ContentText>
    </Box>
  );
};
