import { EquipmentServiceType } from '@models/contract';
import { OrderType } from '@models/orders';

export const equipmentServiceTypesToOrderTypesMap: { [key in EquipmentServiceType]: OrderType[] } =
  {
    'Pick-up': ['COLLECTION'],
    Submission: ['DELIVERY'],
    Clearance: ['EMPTYING'],
    Exchange: ['COLLECTION', 'DELIVERY'],
  };

//As per requirements, Exchange equipment service type is not supported in the orders creation process
export const orderTypesToEquipmentServiceTypesMap: { [key in OrderType]: EquipmentServiceType } = {
  COLLECTION: 'Pick-up',
  DELIVERY: 'Submission',
  EMPTYING: 'Clearance',
};
