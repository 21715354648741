import i18n, { Namespace } from '@config/i18n';
import { ContractsFilterStateProvider } from '@contexts/ContractsFiltersContext';
import { ContentText, Button, IconAdd } from '@fortum/elemental-ui';
import { FC, useState, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationHeaderWithMarginBottom, OrdersCreationContext } from '../components';
import {
  NoServicesSelectedContainer,
  WideModal,
  headerTextStyles,
  instructionsTextStyles,
  shortDescriptionTextStyles,
} from './components/styles';
import { ServicesSelector } from './components/ServicesSelector';
import { ServicesTiles } from './components/ServicesTiles';
import { VerticalContainer } from '@components/styles';
import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA } from '../config';
import { findCreationForm } from '@utils/ordersCreation/forms';
import { useScrollToServiceTile } from './components/useScrollToServiceTile';

export const OrdersCreation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModalOpen = useCallback(() => setModalOpen(prev => !prev), []);

  const { t } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'common']);

  const { serviceTilesContainerRef, setContractIdentifierToScrollTo } = useScrollToServiceTile();

  const { creationForms, createEmptyCreationForm, initializeNewOrderForm, clearForms } =
    useContext(OrdersCreationContext);

  const onContractChoose = useCallback(
    (contractIdentifier: string) => {
      const creationForm = findCreationForm(creationForms, contractIdentifier);

      if (creationForm && creationForm.serviceSummary) {
        initializeNewOrderForm(contractIdentifier, creationForm.serviceSummary);
        setContractIdentifierToScrollTo(contractIdentifier);
      } else {
        createEmptyCreationForm(contractIdentifier);
      }

      setModalOpen(false);
    },
    [creationForms],
  );

  const openModal = useCallback(() => setModalOpen(true), []);

  const headerLinkProps = useMemo<PageNavigationHeaderProps['link']>(
    () => ({
      id: 'navigate-back-link',
      description: t('orderCreation:returnToPreviousPage'),
      onClick: () => (location?.state?.contractIdentifier ? navigate(-1) : clearForms()),
    }),
    [i18n.language, location],
  );

  return (
    <>
      <NavigationHeaderWithMarginBottom
        link={headerLinkProps}
        title={t('orderCreation:pageHeader')}
      />

      {creationForms.length === 0 ? (
        <NoServicesSelectedContainer>
          <VerticalContainer>
            <ContentText {...headerTextStyles}>{t('wasteDetails:wasteServiceDetails')}</ContentText>
            <ContentText {...instructionsTextStyles}>
              {t('orderCreation:noServicesSelectedPage.instruction')}
            </ContentText>
          </VerticalContainer>

          <ContentText {...shortDescriptionTextStyles}>
            {t('orderCreation:noServicesSelectedPage.shortDescription')}
          </ContentText>

          <Button status="secondary" leftIcon={<IconAdd />} onClick={toggleModalOpen}>
            {t('common:chooseService')}
          </Button>
        </NoServicesSelectedContainer>
      ) : (
        <ServicesTiles onChooseOtherServiceClick={openModal} ref={serviceTilesContainerRef} />
      )}

      <WideModal fullScreen="desktop" opened={modalOpen} onClose={toggleModalOpen}>
        <ContractsFilterStateProvider
          additionalFilteringForOrdersCreation={
            APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA
          }
        >
          <ServicesSelector onClose={toggleModalOpen} onContractChoose={onContractChoose} />
        </ContractsFilterStateProvider>
      </WideModal>
    </>
  );
};
