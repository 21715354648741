import { SupportedLanguage, fallbackLang } from '@common/languages';
import { QlikReportConfig, ReportIds } from './types';
import { qlikAppConfig } from './connection';
import { getUserAccessInfo } from '@utils/user';

// TODO: uncomment when Norvegian translations will be finished. Add Norwegian to CompanyNameSelectionFieldName

const CostsReports: ReportIds = {
  en: process.env.REACT_APP_CO2_REPORT_ID_EN ?? '',
  fi: process.env.REACT_APP_CO2_REPORT_ID_FI ?? '',
  sv: process.env.REACT_APP_CO2_REPORT_ID_SV ?? '',
  // nb: process.env.REACT_APP_CO2_REPORT_ID_NO ?? '',
};

const CompanyNameSelectionFieldName: { [key in SupportedLanguage]: string } = {
  en: 'COMPANY NAME',
  fi: 'YRITYKSEN NIMI',
  sv: 'FÖRETAGSNAMN',
};

export interface Co2ReportObjects {
  filters: {
    companyName: string;
    companySiteCity: string;
    executionYear: string;
    executionMonth: string;
    hazardousNonHazardous: string;
    transportEmission: string;
  };
  kpis: {
    co2eEmissions: string;
    avoidedCo2eEmissions: string;
    netCo2eEmissions: string;
    largestCo2eWasteProducing: string;
  };
  charts: {
    totalWasteAmount: string;
    totalEmissions: string;
    top3NetCo2eProducingWaste: string;
    emissionsByLogisticsAndTreatment: string;
    co2eEmissions: string;
    co2eEmissionsAvoided: string;
    emissionsByWasteTreatmentMethodCo2: string;
  };
  visualization: {
    table: string;
  };
}

const ReportCommonObjectsIds: Co2ReportObjects = {
  filters: {
    companyName: 'phTPh',
    companySiteCity: 'FRgkhc',
    executionYear: 'aUvjPn',
    executionMonth: 'FkdPDw',
    hazardousNonHazardous: 'XsXGJFB',
    transportEmission: 'EUmJS',
  },
  kpis: {
    co2eEmissions: 'jMmq',
    avoidedCo2eEmissions: 'WxTrGXA',
    netCo2eEmissions: 'NrPU',
    largestCo2eWasteProducing: 'qwRJjC',
  },
  charts: {
    totalWasteAmount: 'cCyxV',
    totalEmissions: 'SpecVbP',
    top3NetCo2eProducingWaste: 'HRafe',
    emissionsByLogisticsAndTreatment: 'qkJLcm',
    co2eEmissions: 'VRjptC',
    co2eEmissionsAvoided: 'GxPMysJ',
    emissionsByWasteTreatmentMethodCo2: 'jJsJe',
  },
  visualization: {
    table: 'HDpSyK',
  },
};

const ReportsObjectsIdsPerLang: { [key in SupportedLanguage]: Co2ReportObjects } = {
  en: { ...ReportCommonObjectsIds },
  fi: { ...ReportCommonObjectsIds },
  sv: { ...ReportCommonObjectsIds },
  /* nb: {
    ...ReportCommonObjectsIds,
    visualization: {
      ...ReportCommonObjectsIds.visualization,
      dimensions: {
        ...ReportCommonObjectsIds.visualization.dimensions,
        customerHierarchy: undefined,
      },
    },
  },
  */
};

export const getCo2ReportConfig = (lang: SupportedLanguage): QlikReportConfig<Co2ReportObjects> => {
  const staticConfig = {
    qlikAppConfig: {
      ...qlikAppConfig,
      appId: CostsReports[lang] || CostsReports[fallbackLang],
    },
    defaultSelections: [],
    objectIds: ReportsObjectsIdsPerLang[lang],
  };

  const userAccessInfo = getUserAccessInfo();

  if (!(userAccessInfo.isInternal && userAccessInfo.isAdmin)) {
    return staticConfig;
  }

  return {
    ...staticConfig,
    mandatorySelections: [
      {
        fieldName: CompanyNameSelectionFieldName[lang],
        values: userAccessInfo.selectedBusinessPartners.map(bp => bp.name),
      },
    ],
  };
};
