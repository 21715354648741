import { ResponseCodes } from 'http-constants-ts';

export const CustomHttpHeaders = {
  AUTH_TOKEN: 'X-Auth-Token',
  USER_ID: 'X-User-Id',
  OCP_APIM_SUBSCRIPTION_KEY: 'Ocp-Apim-Subscription-Key',
  AUTH0: 'X-Auth-System',
  QLIK_XRF_KEY: 'X-Qlik-xrfkey',
};

export class HttpError extends Error {
  constructor(
    private statusCode: number,
    private statusText: string,
  ) {
    super(`Failed to fetch: ${statusCode} (${statusText})`);
  }

  getStatusCode = () => this.statusCode;
  getStatusText = () => this.statusText;
}

export const isHttpError = (error: Error): error is HttpError =>
  'getStatusCode' in error && 'getStatusText' in error;

export const isUnauthorizedError = (error: Error) =>
  isHttpError(error) && error.getStatusCode() === ResponseCodes.UNAUTHORIZED;
