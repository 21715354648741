import { Pagination } from '@components/Pagination';
import { ErrorView } from '@components/layout';
import { Table } from '@components/styles';
import { Box, ContentText, CssValue, spacing, TableProps } from '@fortum/elemental-ui';
import { Order } from '@models/orders';
import styled from 'styled-components';

export const HANDLING_STATUS_COLUMN_MIN_WIDTH_PX = '144px';
export const EXECUTION_DATE_COLUMN_MIN_WIDTH_PX = '120px';
export const WASTE_DESCRIPTION_COLUMN_MIN_WIDTH_PX = '184px';
export const WASTE_DESCRIPTION_COLUMN_MAX_WIDTH_PX = '240px';
export const TRANSPORT_TYPE_COLUMN_MIN_WIDTH_PX = '144px';
export const EQUIPMENT_TRANSLATIONS_COLUMN_MIN_WIDTH_PX = '184px';
export const EQUIPMENT_TRANSLATIONS_COLUMN_MAX_WIDTH_PX = '240px';
export const BUSINESS_PARTNER_COLUMN_WIDTH_PX = '163px';
export const SITE_COLUMN_MIN_WIDTH_PX = '184px';
export const SITE_COLUMN_MAX_WIDTH_PX = '240px';

export const LayoutContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

/**
 * It is positioned absolutely to minimize the "jumping" of the table layout on accordion open
 */
export const StyledPaginationLayout = styled(Pagination)`
  position: absolute;
  bottom: 0;
  z-index: 2;

  box-shadow: 0 6px 15px ${spacing.xxxs} rgba(0, 0, 0, 0.15);
`;

interface OrdersTableProps {
  $pageHeaderHeight: CssValue;
  $paginationHeight: CssValue;
}

export const StyledOrdersTable = styled(Table)<TableProps<Order> & OrdersTableProps>`
  height: ${props =>
    `calc(100vh - ${props.$pageHeaderHeight} - var(--app-header-height) - ${props.$paginationHeight})`};
  margin-bottom: ${props => props.$paginationHeight};
  table {
    width: 100%;
  }
`;

export const WordBreakingContentText = styled(ContentText)`
  word-wrap: break-word;
`;

export const StyledErrorView = styled(ErrorView)`
  flex: unset;
  margin: auto;
`;
