import { CompanyDetails } from '@components/CompanyDetails';
import { VerticalListItem } from '@components/index';
import { VerticalContainer } from '@components/styles';
import { Col, ContentText, Row } from '@fortum/elemental-ui';
import { displayedValue } from '@utils/dataOperations';
import { translate } from '@utils/internationalization';
import { getListItemTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { ServiceSummary } from 'src/types/ordersCreation';
import {
  rowWithMarginBottomStyles,
  fullWidthColStyles,
  detailsHeaderTextStyles,
  rowWithBigMarginBottomStyles,
  detailsColStyles,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { camelCase } from 'lodash';

interface ServiceDetailsProps {
  serviceSummary: ServiceSummary | undefined;
  isLoading: boolean;
}

export const ServiceDetails: FC<ServiceDetailsProps> = ({ serviceSummary, isLoading }) => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'services']);

  const serviceTypeValue = serviceSummary
    ? translate(camelCase(serviceSummary.serviceType), 'domain', 'contractType')
    : '';

  return (
    <>
      <Row {...rowWithMarginBottomStyles}>
        <Col {...fullWidthColStyles}>
          <ContentText {...detailsHeaderTextStyles} data-testid="service-details-label">
            {t('wasteDetails:wasteServiceDetails')}
          </ContentText>
        </Col>
      </Row>

      <Row {...rowWithBigMarginBottomStyles} data-testid="service-details">
        <Col {...detailsColStyles}>
          <VerticalContainer>
            <VerticalListItem
              label={t('services:wasteType')}
              data-testid={getListItemTestId('waste-type')}
              displayPlaceholder={isLoading}
            >
              {displayedValue(serviceSummary?.wasteDescription)}
            </VerticalListItem>

            <VerticalListItem
              label={t('wasteDetails:serviceType')}
              data-testid={getListItemTestId('service-type')}
              displayPlaceholder={isLoading}
            >
              {serviceTypeValue}
            </VerticalListItem>

            <VerticalListItem
              label={t('wasteDetails:equipmentType')}
              data-testid={getListItemTestId('equipment-type')}
              displayPlaceholder={isLoading}
            >
              {displayedValue(serviceSummary?.equipmentDescription)}
            </VerticalListItem>
          </VerticalContainer>
        </Col>

        <Col {...detailsColStyles}>
          <VerticalContainer>
            <VerticalListItem
              label={t('wasteDetails:transportDetails.transportType')}
              data-testid={getListItemTestId('transport-type')}
              displayPlaceholder={isLoading}
            >
              {translate(displayedValue(serviceSummary?.transportType), 'domain', 'transportType')}
            </VerticalListItem>
            <VerticalListItem
              label={t('wasteDetails:company')}
              data-testid={getListItemTestId('company')}
              displayPlaceholder={isLoading}
            >
              {serviceSummary ? <CompanyDetails company={serviceSummary.company} /> : ''}
            </VerticalListItem>
          </VerticalContainer>
        </Col>
      </Row>
    </>
  );
};
