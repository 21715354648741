import { Namespace } from '@config/i18n';
import { Box, ContentText, Grid } from '@fortum/elemental-ui';
import { MapLayout } from '@routes/home/components/Map';
import { useTranslation } from 'react-i18next';
import { InfoSection, QuickAccessSection } from './components';
import {
  descriptionStyles,
  gridLayoutStyles,
  infoTitleStyles,
  mapLayoutStyles,
  pageContainerStyles,
  pageContentContainerStyles,
  pageTitleProps,
  quickAccessStyles,
  titleContainerStyles,
  titleStyles,
} from './styles';
import { PAGE_HEADER_TEST_ID } from '@common/testIds';

export const Home = () => {
  const { t } = useTranslation<Namespace>('home');

  return (
    <Box {...pageContainerStyles}>
      <Box {...titleContainerStyles} data-testid={PAGE_HEADER_TEST_ID}>
        <ContentText {...pageTitleProps}>{t('pageTitle')}</ContentText>
      </Box>

      <Box {...pageContentContainerStyles}>
        <ContentText {...descriptionStyles}>{t('pageDescription')}</ContentText>

        <Grid {...gridLayoutStyles}>
          <ContentText {...titleStyles}>{t('sections.quickAccess.title')}</ContentText>
          <QuickAccessSection {...quickAccessStyles} />
          <MapLayout {...mapLayoutStyles} />
          <ContentText {...infoTitleStyles}>{t('sections.info.title')}</ContentText>
          <InfoSection />
        </Grid>
      </Box>
    </Box>
  );
};
