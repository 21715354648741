import { QlikSelectionObject } from '@components/qlik';
import { CostReportObjects } from '@config/qlik';
import { FC } from 'react';

interface FiltersProps {
  objectIds: CostReportObjects;
}

export const Filters: FC<FiltersProps> = ({ objectIds }) => (
  <>
    <QlikSelectionObject id={objectIds.filters.executionYear} dataTestId="filter-execution-year" />

    <QlikSelectionObject
      id={objectIds.filters.executionQuarter}
      dataTestId="filter-execution-quarter"
    />
    <QlikSelectionObject
      id={objectIds.filters.executionMonth}
      dataTestId="filter-execution-month"
    />

    <QlikSelectionObject id={objectIds.filters.invoiceYear} dataTestId="filter-invoice-year" />
    <QlikSelectionObject
      id={objectIds.filters.invoiceQuarter}
      dataTestId="filter-invoice-quarter"
    />

    <QlikSelectionObject id={objectIds.filters.invoiceMonth} dataTestId="filter-invoice-month" />
  </>
);
