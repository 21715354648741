import { orderTypesToEquipmentServiceTypesMap } from '@config/order';
import { CreationForms, OrdersCreationRequestBody } from 'src/types/ordersCreation';

export const mapCreationFormsToRequestBody = (
  creationForms: CreationForms,
): OrdersCreationRequestBody => {
  const orders = creationForms.flatMap(creationForm =>
    creationForm.forms.map(orderForm => ({
      uuid: orderForm.formIdentifier,
      contractNo: creationForm.contractNo,
      dataSource: creationForm.dataSource,
      equipmentEntryType: orderTypesToEquipmentServiceTypesMap[orderForm.values.orderType],
      orderLevelReference: orderForm.values.orderLevelReference,
      additionalComments: orderForm.values.comments,
      specifiedWasteDescription: orderForm.values.specifiedWasteDescription,
      locationPointAdditionalInfo: orderForm.values.locationPointAdditionalInfo,
      contactPersonId: orderForm.values.contactPersonId,
      estimatedWasteQuantity: orderForm.values.estimatedWasteQuantity ?? null,
      equipmentQuantity: orderForm.values.equipmentQuantity,
    })),
  );

  return {
    data: orders,
  };
};
