import { useDataExport, NO_DATA_ERROR_MESSAGE } from '@components/qlik';
import { Stylable, Testable } from '@components/types';
import { Button } from '@fortum/elemental-ui';
import { FC, useRef, useState, useEffect, useCallback } from 'react';
import { ExportDataHandler } from '../types';

interface ExportButtonProps extends Stylable, Testable {
  text: string;
  exportDataHandler: ExportDataHandler;
}

export const ExportButton: FC<ExportButtonProps> = ({
  text,
  className,
  exportDataHandler,
  'data-testid': testId,
}) => {
  const { exportData } = useDataExport();

  const [exportInProgress, setExportInProgress] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const exportTableData = useCallback(() => {
    setExportInProgress(true);

    return exportData(exportDataHandler.objectId)
      .catch(error => {
        if (error.message === NO_DATA_ERROR_MESSAGE) {
          exportDataHandler.onEmptyData();
        } else {
          exportDataHandler.onError();
        }
      })
      .finally(() => {
        if (!isMounted.current) {
          return;
        }

        setExportInProgress(false);
      });
  }, [exportData, exportDataHandler]);

  return (
    <Button
      variant="condensed"
      onClick={exportTableData}
      disabled={exportInProgress}
      className={className}
      data-testid={testId || 'export-button'}
    >
      {text}
    </Button>
  );
};
