import { Row, Col } from '@fortum/elemental-ui';
import { FC, useContext, useMemo } from 'react';
import { CreationForm } from 'src/types/ordersCreation';
import { OrderTile } from '../OrderTile/OrderTile';
import { SingleOrderCreationContextProvider } from '../SingleOrderCreationContextProvider';
import { rowWithoutMarginHorizontal, orderTileColStyles } from './styles';
import { Contract } from '@models/contract';
import { useContactsByBusinessPartner } from '@data/hooks/useContactsByBusinessPartner';
import { OrderType } from '@models/orders';
import { OrdersCreationContext } from '@routes/ordersCreation/components';

interface OrderTilesProps {
  contractIdentifier: string;
  contract: Contract;
  ordersForms: CreationForm['forms'];
  availableOrderTypes: OrderType[];
}

export const OrderTiles: FC<OrderTilesProps> = ({
  contract,
  ordersForms,
  contractIdentifier,
  availableOrderTypes,
}) => {
  const { deleteOrder } = useContext(OrdersCreationContext);
  const contactsDataHandler = useContactsByBusinessPartner(contract?.generalInfo.customerCode);

  const isDeletionAllowed = useMemo(() => ordersForms.length > 1, [ordersForms.length]);

  return ordersForms.map((form, index) => (
    <Row {...rowWithoutMarginHorizontal} key={form.formIdentifier}>
      <Col {...orderTileColStyles}>
        <SingleOrderCreationContextProvider
          contract={contract}
          contactsDataHandler={contactsDataHandler}
          availableOrderTypes={availableOrderTypes}
          contractIdentifier={contractIdentifier}
          orderForm={form}
        >
          <OrderTile
            data-testid={`order-tile-${index}`}
            orderIndex={index}
            deletionAllowed={isDeletionAllowed}
            deleteOrder={deleteOrder}
          />
        </SingleOrderCreationContextProvider>
      </Col>
    </Row>
  ));
};
