import { QueryKeys } from '@common/query';
import { fetchServiceFilters } from '@data/api/service';
import { UserAccess } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternalAdmin } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { ContractFiltersKeys, ContractMetaFilters } from 'src/types/services';
import { QueryOptions } from './types';

const serviceFiltersQuery = (
  userAccess: UserAccess,
  language: string,
  additionalFilteringForOrdersCreation: boolean,
  filtersKeys?: ContractFiltersKeys,
): QueryOptions<ContractMetaFilters> => ({
  queryKey: isUserAccessOfInternalAdmin(userAccess)
    ? [
        QueryKeys.serviceFiltersAdmin,
        language,
        filtersKeys,
        userAccess.selectedBusinessPartners.map(bp => bp.id).join(','),
      ]
    : [QueryKeys.serviceFilters, language, filtersKeys],
  queryFn: () =>
    fetchServiceFilters(userAccess, language, additionalFilteringForOrdersCreation, filtersKeys),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useServiceFilters = (
  filtersKeys: ContractFiltersKeys | undefined,
  additionalFilteringForOrdersCreation = false,
) => {
  const { i18n } = useTranslation();
  return useQuery<ContractMetaFilters, Error>(
    serviceFiltersQuery(
      getUserAccessInfo(),
      i18n.language,
      additionalFilteringForOrdersCreation,
      filtersKeys,
    ),
  );
};
