import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import {
  ContentText,
  IconBin,
  IconDownloadFromCloud,
  IconEmail,
  IconHome,
  IconTruckOutline,
  colors,
  fontSizes,
} from '@fortum/elemental-ui';
import { getEnvProperty } from '@utils/general';
import { BaseSyntheticEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavItem, SubNavigation } from './NavItem';
import { AppVersionInfo, Navigation } from './styles';
import { co2ReportToggleOn } from '@utils/featureToggles';

interface AppNavbarProps {
  disabled?: boolean;
}

export const AppNavbar: FC<AppNavbarProps> = ({ disabled = false }) => {
  const { t } = useTranslation<Namespace[]>(['mainLayout', 'reports']);

  const subnavigationPaths = [
    paths.costDetailsReport,
    paths.wasteDetailsReport,
    paths.dashboardReport,
  ];

  const onItemClick = useCallback(
    (e: BaseSyntheticEvent) => disabled && e.preventDefault(),
    [disabled],
  );

  const renderVersionDesc = useCallback(() => {
    if (!getEnvProperty('REACT_APP_ENV_DESC_TO_BE_DISPLAYED')) return null;

    const description = getEnvProperty('REACT_APP_RELEASE_VERSION')
      ? `${getEnvProperty('REACT_APP_ENV_DESC_TO_BE_DISPLAYED')} ${getEnvProperty('REACT_APP_RELEASE_VERSION').substring(0, 5)}`
      : getEnvProperty('REACT_APP_ENV_DESC_TO_BE_DISPLAYED');

    return (
      <AppVersionInfo>
        <ContentText color={colors.snowWhite} fontSize={fontSizes.xs}>
          {description}
        </ContentText>
      </AppVersionInfo>
    );
  }, []);

  return (
    <Navigation>
      <ul>
        <NavItem
          to={paths.main}
          onClick={onItemClick}
          disabled={disabled}
          icon={<IconHome />}
          description={t('mainLayout:home')}
        />

        <NavItem
          to={paths.orders}
          onClick={onItemClick}
          disabled={disabled}
          icon={<IconTruckOutline />}
          description={t('mainLayout:orders')}
        />

        <NavItem
          to={paths.services}
          onClick={onItemClick}
          disabled={disabled}
          icon={<IconBin />}
          description={t('mainLayout:services')}
        />

        <SubNavigation
          description={t('mainLayout:reports')}
          disabled={disabled}
          subnavigationPaths={subnavigationPaths}
        >
          <NavItem
            to={paths.dashboardReport}
            onClick={onItemClick}
            description={t('reports:dashboard.navigation')}
            disabled={disabled}
            subNavItem={true}
          />
          <NavItem
            to={paths.wasteDetailsReport}
            onClick={onItemClick}
            description={t('reports:wasteQty.navigation')}
            disabled={disabled}
            subNavItem={true}
          />
          <NavItem
            to={paths.costDetailsReport}
            onClick={onItemClick}
            description={t('reports:cost.navigation')}
            disabled={disabled}
            subNavItem={true}
          />
        </SubNavigation>

        {co2ReportToggleOn && (
          <NavItem
            to={paths.co2Report}
            onClick={onItemClick}
            disabled={disabled}
            icon={<IconDownloadFromCloud />}
            description={t('reports:co2.navigation')}
          />
        )}
        <NavItem
          to={paths.contactUs}
          onClick={onItemClick}
          disabled={disabled}
          icon={<IconEmail />}
          description={t('mainLayout:contact')}
        />
      </ul>

      {renderVersionDesc()}
    </Navigation>
  );
};
