import { ContentText, fontSizes } from '@fortum/elemental-ui';
import { HeaderContainer, HeaderButtonsContainer } from '../../styles';
import { BookmarksContext } from '@components/qlik';
import { FC, useContext } from 'react';
import { BookmarksManagement } from './BookmarksManagement';
import { PAGE_HEADER_TEST_ID } from '@common/testIds';

export interface PageHeaderProps {
  title: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ title }) => {
  const bookmarksContext = useContext(BookmarksContext);

  return (
    <HeaderContainer data-testid={PAGE_HEADER_TEST_ID}>
      <ContentText fontSize={fontSizes.xl}>{title}</ContentText>
      {!!bookmarksContext && (
        <HeaderButtonsContainer>
          <BookmarksManagement />
        </HeaderButtonsContainer>
      )}
    </HeaderContainer>
  );
};
