import { FC, ComponentPropsWithoutRef, useMemo } from 'react';
import {
  Container,
  StyledInputField,
  InputLabel,
  StyledErrorMessage,
  ReadOnlyContainer,
  ReadOnlyInputField,
  ReadOnlyIconContainer,
  IconContainer,
} from './styles';
import { Stylable, Testable } from '@components/types';
import {
  InputFieldProps as ElementalInputFieldProps,
  renderComponentOrElement,
  CommonIconProps,
} from '@fortum/elemental-ui';

type NativeInputProps = ComponentPropsWithoutRef<'input'>;

type InputFieldProps = {
  label: string;
  name: string;
  required?: boolean;
  errorMessage?: string;
  showError?: boolean;
  size?: ElementalInputFieldProps['size'];
  icon?: ElementalInputFieldProps['leftIcon'];
} & Testable &
  Stylable &
  Omit<NativeInputProps, 'size'>;

const iconStyles: CommonIconProps = { size: 24 };

export const InputField: FC<InputFieldProps> = ({
  label,
  name,
  errorMessage,
  showError = false,
  icon,
  className,
  size = 'l',
  'data-testid': testId,
  readOnly = false,
  disabled,
  ...nativeProps
}) => {
  const labelText = useMemo<string>(
    () => (nativeProps.required ? `*${label}` : label),
    [nativeProps.required, label],
  );

  const { labelId, errorMessageId } = useMemo(
    () => ({ labelId: `${name}_label`, errorMessageId: `${name}_error` }),
    [name],
  );

  return readOnly ? (
    <ReadOnlyContainer className={className}>
      <ReadOnlyInputField
        id={name}
        type="text"
        readOnly={readOnly}
        $size={size}
        {...nativeProps}
        $withIcon={!!icon}
        aria-required={nativeProps.required || undefined}
        aria-labelledby={labelId}
      />

      {icon && (
        <ReadOnlyIconContainer data-testid="left-icon-container" aria-label={label} $size={size}>
          {renderComponentOrElement(icon, iconStyles)}
        </ReadOnlyIconContainer>
      )}

      <InputLabel $size={size} id={labelId} htmlFor={name} $withIcon={!!icon}>
        {labelText}
      </InputLabel>
    </ReadOnlyContainer>
  ) : (
    <Container data-testid={testId} $error={showError} $disabled={!!disabled} className={className}>
      <StyledInputField
        id={name}
        type="text"
        $size={size}
        {...nativeProps}
        $withIcon={!!icon}
        $error={showError}
        disabled={disabled}
        aria-disabled={!!disabled || undefined}
        aria-required={nativeProps.required || undefined}
        aria-labelledby={labelId}
        aria-invalid={!!showError || undefined}
        aria-describedby={showError ? errorMessageId : undefined}
      />

      {icon && (
        <IconContainer
          data-testid="left-icon-container"
          aria-label={label}
          $size={size}
          $error={showError}
        >
          {renderComponentOrElement(icon, iconStyles)}
        </IconContainer>
      )}

      <InputLabel $size={size} id={labelId} htmlFor={name} $withIcon={!!icon}>
        {labelText}
      </InputLabel>

      {showError && (
        <StyledErrorMessage id={errorMessageId} active>
          {errorMessage}
        </StyledErrorMessage>
      )}
    </Container>
  );
};
