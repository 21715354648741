import { QlikSelectionObject } from '@components/qlik';
import { CostReportObjects } from '@config/qlik';
import { FC } from 'react';
import { VerticalContainerWithGaps } from '../styles';

interface DimensionsProps {
  objectIds: CostReportObjects;
}

export const Dimensions: FC<DimensionsProps> = ({ objectIds }) => (
  <VerticalContainerWithGaps>
    {!!objectIds.visualization.dimensions.company && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.company}
        dataTestId="dimensions-company"
      />
    )}

    {!!objectIds.visualization.dimensions.customerHierarchy && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.customerHierarchy}
        dataTestId="dimensions-customer-hierarchy"
      />
    )}

    {!!objectIds.visualization.dimensions.wasteDetails && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.wasteDetails}
        dataTestId="dimensions-waste-details"
      />
    )}

    {!!objectIds.visualization.dimensions.orderDetails && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.orderDetails}
        dataTestId="dimensions-order-details"
      />
    )}

    {!!objectIds.visualization.dimensions.invoiceDetails && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.invoiceDetails}
        dataTestId="dimensions-invoice-details"
      />
    )}

    {!!objectIds.visualization.dimensions.measures && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.measures}
        dataTestId="dimensions-measures"
      />
    )}
  </VerticalContainerWithGaps>
);
