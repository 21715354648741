import { CreationForm, CreationForms, FormValues, ServiceSummary } from 'src/types/ordersCreation';
import { OrderType } from '@models/orders';
import { destructureContractIdentifier } from '@utils/routes';

const replaceOrderForm = (
  creationForm: CreationForm,
  formIdentifier: string,
  newOrder: CreationForm['forms'][number],
) =>
  creationForm.forms.map(orderForm =>
    orderForm.formIdentifier !== formIdentifier ? orderForm : newOrder,
  );

export const findCreationForm = (creationForms: CreationForms, contractIdentifier: string) =>
  creationForms.find(creationForm => creationForm.contractIdentifier === contractIdentifier);

export const setEmptyOrdersForms = (
  currentCreationForms: CreationForms,
  contractIdentifier: string,
): CreationForms => {
  const { dataSource, contractNo } = destructureContractIdentifier(contractIdentifier);

  if (!findCreationForm(currentCreationForms, contractIdentifier)) {
    return [
      ...currentCreationForms,
      {
        contractIdentifier,
        dataSource: dataSource ?? '',
        contractNo: contractNo ?? '',
        forms: [],
      },
    ];
  }

  return currentCreationForms.map(creationForm => {
    if (creationForm.contractIdentifier !== contractIdentifier) return creationForm;

    return {
      ...creationForm,
      forms: [],
    };
  });
};

export const setSingleOrderForm = (
  currentCreationForms: CreationForms,
  formIdentifier: string,
  contractIdentifier: string,
  serviceSummary: ServiceSummary,
): CreationForms => {
  const initializedOrderForm = getInitialOrderForm(
    formIdentifier,
    serviceSummary.availableOrderTypes[0],
    serviceSummary.contactPersonId,
    serviceSummary.orderLevelReference,
  );

  const contractCreationForm = findCreationForm(currentCreationForms, contractIdentifier);

  const { dataSource, contractNo } = destructureContractIdentifier(contractIdentifier);

  if (!contractCreationForm) {
    const creationForm: CreationForm = {
      contractIdentifier,
      dataSource: dataSource ?? '',
      contractNo: contractNo ?? '',
      serviceSummary,
      forms: [initializedOrderForm],
    };

    return [...currentCreationForms, creationForm];
  }

  return currentCreationForms.map(creationForm => {
    if (creationForm.contractIdentifier !== contractIdentifier) return creationForm;

    return {
      ...creationForm,
      serviceSummary,
      forms: [initializedOrderForm],
    };
  });
};

export const addNewOrderForm = (
  currentCreationForms: CreationForms,
  formIdentifier: string,
  contractIdentifier: string,
  serviceSummary: ServiceSummary,
): CreationForms => {
  const initializedOrderForm = getInitialOrderForm(
    formIdentifier,
    serviceSummary.availableOrderTypes[0],
    serviceSummary.contactPersonId,
    serviceSummary.orderLevelReference,
  );

  const contractCreationForm = findCreationForm(currentCreationForms, contractIdentifier);
  const { dataSource, contractNo } = destructureContractIdentifier(contractIdentifier);

  if (!contractCreationForm) {
    const creationForm: CreationForm = {
      contractIdentifier,
      dataSource: dataSource ?? '',
      contractNo: contractNo ?? '',
      serviceSummary,
      forms: [initializedOrderForm],
    };

    return [...currentCreationForms, creationForm];
  }

  return currentCreationForms.map(creationForm => {
    if (creationForm.contractIdentifier !== contractIdentifier) return creationForm;

    const orderForms = creationForm.forms.find(
      orderForm => orderForm.formIdentifier === formIdentifier,
    )
      ? replaceOrderForm(creationForm, formIdentifier, initializedOrderForm)
      : [...creationForm.forms, initializedOrderForm];

    return {
      ...creationForm,
      serviceSummary,
      forms: orderForms,
    };
  });
};

export const updateOrderForm = <K extends keyof FormValues>(
  forms: CreationForm['forms'],
  formIdentifier: string,
  fieldKey: K,
  value: FormValues[K],
): CreationForm['forms'] =>
  forms.map(form => {
    if (form.formIdentifier !== formIdentifier) return form;

    const temporaryState = { ...form };
    temporaryState.values = temporaryState.values ?? {};

    temporaryState.values[fieldKey] = value;

    return { ...temporaryState };
  });

export const getInitialOrderForm = (
  formIdentifier: string,
  orderType: OrderType,
  contactPersonId: string,
  orderLevelReference?: string,
): CreationForm['forms'][number] => ({
  formIdentifier,
  values: {
    orderType,
    contactPersonId,
    orderLevelReference: orderLevelReference || '',
    equipmentQuantity: 0,
    comments: '',
    specifiedWasteDescription: '',
    locationPointAdditionalInfo: '',
  },
});

export const deleteOrderForm = (
  currentCreationForms: CreationForms,
  contractIdentifier: string,
  formIdentifier: string,
) =>
  currentCreationForms.map(creationForm =>
    creationForm.contractIdentifier !== contractIdentifier
      ? creationForm
      : {
          ...creationForm,
          forms: creationForm.forms.filter(form => form.formIdentifier !== formIdentifier),
        },
  );
