import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalContainerWithSmallGap, VerticalListItemWithPadding } from './styles';
import {
  NO_DATA_BADGE,
  displayedValue,
  getHazardousInfoDescription,
  hasValue,
} from '@utils/dataOperations';
import { getListItemTestId } from '@utils/testsHelpers';
import {
  ADRLabels,
  HPCodes,
  WasteConsistency,
  WasteEwcCode,
  WasteRdCode,
} from '@components/wasteDetails';
import { translate } from '@utils/internationalization';
import { InputField } from '@components/InputField';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';

export const WasteDetailsForm: FC = () => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderCreation']);

  const { form, contract, setSpecifiedWasteDescription } = useContext(SingleOrderCreationContext);

  const { wasteDetails } = contract.serviceDetails;

  const adrValue =
    wasteDetails.adr === null
      ? NO_DATA_BADGE
      : translate(`${wasteDetails.adr}`, 'domain', 'adrWaste');

  const animalicByProductValue =
    wasteDetails.animalicByProduct === null
      ? NO_DATA_BADGE
      : translate(`${wasteDetails.animalicByProduct}`, 'domain', 'animalicByProduct');

  return (
    <Tile header={t('wasteDetails:wasteDetails.details')} data-testid="waste-details-form">
      <VerticalContainerWithSmallGap>
        <VerticalListItemWithPadding
          label={t('wasteDetails:wasteDescription')}
          data-testid={getListItemTestId('waste-description')}
        >
          {displayedValue(contract.generalInfo.wasteDescription)}
        </VerticalListItemWithPadding>

        <InputField
          data-testid="specified-waste-description-input-field"
          name="specified-waste-description"
          label={t('orderCreation:form.specifiedWasteDescription.label')}
          value={form.values.specifiedWasteDescription}
          onChange={setSpecifiedWasteDescription}
          size="m"
        />

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('waste-consistency')}
          label={t('wasteDetails:wasteDetails.wasteConsistency')}
        >
          <WasteConsistency
            consistency={wasteDetails.consistency}
            data-testid="waste-consistency"
          />
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('ewc-code')}
          label={t('wasteDetails:wasteDetails.ewcCode')}
        >
          <WasteEwcCode ewcCode={wasteDetails.ewcCode} />
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('hazard')}
          label={t('wasteDetails:wasteDetails.hazard')}
        >
          {getHazardousInfoDescription(wasteDetails.hazard)}
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('rd-code')}
          label={t('wasteDetails:wasteDetails.rdCode')}
        >
          <WasteRdCode code={wasteDetails.rdCode} data-testid="waste-rd-code" />
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('hazardous-material-classes')}
          label={t('wasteDetails:wasteDetails.adrLabels')}
        >
          <ADRLabels
            data-testid="hazardous-material-classes"
            hazardousMaterialClasses={wasteDetails.hazardousMaterialClasses}
            environmentalHazardous={wasteDetails.environmentalHazardous}
          />
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('un-number')}
          label={t('wasteDetails:wasteDetails.unNumber')}
        >
          {displayedValue(wasteDetails.unNumber)}
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('adr-waste')}
          label={t('wasteDetails:wasteDetails.adrWaste')}
        >
          {adrValue}
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('environmental-hazardous')}
          label={t('wasteDetails:wasteDetails.environmentalHazardous')}
        >
          {translate(
            displayedValue(
              wasteDetails.environmentalHazardous !== null
                ? `${wasteDetails.environmentalHazardous}`
                : null,
            ),
            'domain',
            'environmentallyHazardous',
          )}
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('hp-codes')}
          label={t('wasteDetails:wasteDetails.hpCodes')}
        >
          <HPCodes hpCodes={wasteDetails.hpCodes} data-testid="hp-codes" />
        </VerticalListItemWithPadding>

        {hasValue(wasteDetails.oilType) && (
          <VerticalListItemWithPadding
            data-testid={getListItemTestId('waste-oil-type')}
            label={t('wasteDetails:wasteDetails.wasteOilType')}
          >
            {translate(wasteDetails.oilType, 'domain', 'wasteOilType')}
          </VerticalListItemWithPadding>
        )}

        {hasValue(wasteDetails.popCompounds) && (
          <VerticalListItemWithPadding
            data-testid={getListItemTestId('pop-compounds')}
            label={t('wasteDetails:wasteDetails.popCompounds')}
          >
            {translate(wasteDetails.popCompounds, 'domain', 'analysisPopCode')}
          </VerticalListItemWithPadding>
        )}

        <VerticalListItemWithPadding
          data-testid={getListItemTestId('animalic-by-product')}
          label={t('wasteDetails:wasteDetails.animalicByProduct')}
        >
          {animalicByProductValue}
        </VerticalListItemWithPadding>
      </VerticalContainerWithSmallGap>
    </Tile>
  );
};
