import {
  Box,
  Button,
  CommonBoxProps,
  CommonColProps,
  CommonForwardLinkProps,
  ForwardLink,
  IconAdd,
  colors,
  px2rem,
  spacing,
} from '@fortum/elemental-ui';
import { FC } from 'react';
import { GeneralInfo } from '@models/contract';
import { translate } from '@utils/internationalization';
import { camelCase } from 'lodash';
import { displayedValue } from '@utils/dataOperations';
import { HeaderCell, TableTile, ValueCell, HeaderRow, ValuesRow } from '@components/TableTile';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';
import { paths } from '@config/routes';
import { useNavigate } from 'react-router-dom';
import { ordersCreationToggleOn } from '@utils/featureToggles';
import { shouldAllowOrdersCreation } from '@utils/services';

interface ContractDetailsTileProps {
  generalInfo: GeneralInfo;
  numberOfOrders: number;
  contractIdentifier: string;
}

const contractNoColProps: CommonColProps = {
  xl: 2,
  xxs: 3,
};

const openOrdersColProps: CommonColProps = {
  xl: 2,
  xxs: 3,
};

const serviceTypeColProps: CommonColProps = {
  xl: 8,
  xxs: 6,
};

const linkProps: CommonForwardLinkProps = {
  fontSize: '18px',
  color: colors.oceanGreen,
};

const linkBoxProps: CommonBoxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: px2rem(48),
};

export const ContractDetailsTile: FC<ContractDetailsTileProps> = ({
  generalInfo,
  numberOfOrders,
  contractIdentifier,
}) => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderCreation']);

  const navigate = useNavigate();

  const numberOfOpenOrders = t('numberOfOrders', { numberOfOrders });
  const serviceTypeValue = translate(camelCase(generalInfo.serviceType), 'domain', 'contractType');

  const shouldDisplayOrdersCreationButton =
    ordersCreationToggleOn &&
    shouldAllowOrdersCreation(contractIdentifier, generalInfo.serviceType);

  return (
    <>
      <Box
        {...linkBoxProps}
        marginBottom={shouldDisplayOrdersCreationButton ? spacing.xxs : spacing.xxxs}
      >
        <ForwardLink
          {...linkProps}
          onClick={() =>
            navigate(paths.orders, { state: { contractNo: generalInfo.contractNumber } })
          }
          data-testid="link-to-orders"
        >
          {t('wasteDetails:seeRelatedOrders')}
        </ForwardLink>

        {shouldDisplayOrdersCreationButton && (
          <Button
            data-testid="navigate-to-orders-creation-button"
            rightIcon={<IconAdd />}
            status="secondary"
            variant="condensed"
            onClick={() => navigate(paths.ordersCreation, { state: { contractIdentifier } })}
          >
            {t('orderCreation:createNewOrder')}
          </Button>
        )}
      </Box>

      <TableTile>
        <HeaderRow>
          <HeaderCell text={t('wasteDetails:contractNo')} gridColumnProps={contractNoColProps} />
          <HeaderCell text={t('wasteDetails:queue')} gridColumnProps={openOrdersColProps} />
          <HeaderCell text={t('wasteDetails:serviceType')} gridColumnProps={serviceTypeColProps} />
        </HeaderRow>

        <ValuesRow>
          <ValueCell
            text={displayedValue(generalInfo.contractNumber)}
            gridColumnProps={contractNoColProps}
            data-testid="contract-number-value-col"
          />

          <ValueCell
            text={numberOfOpenOrders}
            gridColumnProps={openOrdersColProps}
            data-testid="open-orders-value-col"
          />

          <ValueCell
            text={serviceTypeValue}
            gridColumnProps={serviceTypeColProps}
            data-testid="service-type-value-col"
          />
        </ValuesRow>
      </TableTile>
    </>
  );
};
