import { FC } from 'react';
import {
  QlikObject,
  useDefaultSelections,
  useCurrentSelections,
  BookmarksContextProvider,
} from '@components/qlik';
import { BottomContainer, TopContainer } from './styles';
import { ErrorViewWithBackground, ExportButtonPositionedRight, ReportWrapper } from '../styles';
import { ReportProps } from '../types';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { PageHeader, SelectionsManagement } from '../components';
import { CostReportObjects } from '@config/qlik/costReport';
import { CenteredLoader } from '@components/layout/styles';
import { paths } from '@config/routes';
import { Filters } from './Filters';
import { Dimensions } from './Dimensions';

export const Report: FC<ReportProps<CostReportObjects>> = ({
  defaultSelections,
  exportDataHandler,
  objectIds,
  mandatorySelections,
}) => {
  const { t } = useTranslation<Namespace[]>(['errors', 'reports', 'common']);

  const { qSelections, clearSelections, isError, isLoading } = useCurrentSelections();

  const { mandatorySelectionsError, mandatorySelectionsValidationInProgress } =
    useDefaultSelections(defaultSelections, mandatorySelections);

  if (mandatorySelectionsValidationInProgress) {
    return <CenteredLoader />;
  }
  if (mandatorySelectionsError) {
    return (
      <ErrorViewWithBackground
        header={t('errors:reports.nothingToReport.header')}
        message={t('errors:reports.nothingToReport.message')}
        link={{
          navigatePath: paths.selectCustomer,
          text: t('errors:reports.nothingToReport.linkMessage'),
        }}
      />
    );
  }

  return (
    <BookmarksContextProvider qSelections={qSelections}>
      <PageHeader title={t('reports:cost.title')} />
      <ReportWrapper>
        <SelectionsManagement
          qSelections={qSelections}
          clearSelections={clearSelections}
          isSelectionsFetchingError={isError}
          isSelectionsLoading={isLoading}
        />
        <TopContainer>
          <Filters objectIds={objectIds} />

          <ExportButtonPositionedRight
            exportDataHandler={exportDataHandler}
            text={t('common:export')}
          />
        </TopContainer>

        <BottomContainer>
          <Dimensions objectIds={objectIds} />

          <QlikObject
            id={objectIds.visualization.table}
            height="100%"
            minHeight="500px"
            dataTestId="table"
          />
        </BottomContainer>
      </ReportWrapper>
    </BookmarksContextProvider>
  );
};
