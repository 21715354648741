import { horizontalContainer, verticalContainer } from '@components/styles';
import { colors, Modal, px2rem, Select, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const StyledSelect: typeof Select = styled(Select)`
  button {
    background: ${colors.lightGrey};
  }
`;

export const StyledModal = styled(Modal)`
  --modal-content-max-height: ${px2rem(630)};
  --modal-content-max-width: ${px2rem(770)};

  > div {
    max-height: var(--modal-content-max-height);
    max-width: var(--modal-content-max-width);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TopContainer = styled.div`
  ${verticalContainer};
  align-items: center;
`;

export const MiddleContentContainer = styled.div`
  --notification-height: 59px;
  --row-gap: ${spacing.xs};
  ${verticalContainer};
  margin-top: ${spacing.xxs};
  row-gap: var(--row-gap);
`;

export const BookmarksListContainer = styled.ul<{ $error: boolean }>`
  --header-margin-bottom-on-success: calc(var(--row-gap) + 16px);
  --max-height-without-error: 338px;
  --max-height-with-error: calc(
    var(--max-height-without-error) + var(--header-margin-bottom-on-success) - var(
        --notification-height
      ) - 2 * var(--row-gap)
  );

  border: 1px solid ${colors.fogGrey};
  padding: ${spacing.xs};

  ${verticalContainer};
  row-gap: ${spacing.xs};
  align-items: flex-start;
  overflow: auto;

  width: 510px;
  max-width: 510px;
  box-sizing: border-box;
  max-height: ${({ $error }) =>
    $error ? `var(--max-height-with-error)` : `var(--max-height-without-error)`};
  height: ${({ $error }) =>
    $error ? `var(--max-height-with-error)` : `var(--max-height-without-error)`};
`;

export const BookmarkItemContainer = styled.li`
  ${horizontalContainer};
  width: 100%;
  justify-content: space-between;
  column-gap: ${spacing.xxs};
`;

export const BookmarkDetailsContainer = styled.div`
  ${verticalContainer};
  text-align: start;
  flex: 1;

  padding: ${spacing.xxxs} 0;
`;
