import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Container,
  gridStyles,
  orderTileColStyles,
  rowWithMarginTop,
  addButtonStyles,
  orderTilesPlaceholderStyles,
} from './styles';
import { Button, Col, Grid, IconAdd, Placeholder, Row, useToggle } from '@fortum/elemental-ui';
import { ServiceSummary } from 'src/types/ordersCreation';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { ServiceTileHeader } from './ServiceTileHeader';
import { mapContractToServiceSummary } from '@utils/contract';
import { useContractDetails } from '@data/hooks/useContractDetails';
import { CreationForm } from 'src/types/ordersCreation';
import { orderTypesSortingOrder } from '@routes/ordersCreation/config';
import {
  ConfirmationModal,
  IncorrectDataError,
  OrdersCreationContext,
} from '@routes/ordersCreation/components';
import { createServiceTileId } from '@utils/ordersCreation';
import { ServiceDetails } from './ServiceDetails';
import { OrderTiles } from './OrderTiles';

export interface ServiceTileProps {
  serviceIndex: number;
  ordersCreationForm: CreationForm;
  onChooseOtherServiceClick: () => void;
}

export const ServiceTile: FC<ServiceTileProps> = ({
  serviceIndex,
  ordersCreationForm,
  onChooseOtherServiceClick,
}) => {
  const { t, i18n } = useTranslation<Namespace[]>([
    'wasteDetails',
    'services',
    'common',
    'orderCreation',
  ]);

  const { initializeNewOrderForm, setInitialOrderFormWithContractDetails, discardService } =
    useContext(OrdersCreationContext);

  const [modalOpened, toggleModalOpened] = useToggle();

  const {
    data: contract,
    isLoading,
    error,
  } = useContractDetails(
    ordersCreationForm.contractNo,
    ordersCreationForm.dataSource,
    i18n.language,
  );

  const serviceSummary = useMemo<ServiceSummary | undefined>(
    () => (contract ? mapContractToServiceSummary(contract, orderTypesSortingOrder) : undefined),
    [contract],
  );

  const fetchingError = !isLoading && (!contract || error || !serviceSummary);
  const incorrectDataError =
    (contract && serviceSummary && serviceSummary.availableOrderTypes.length === 0) ||
    !ordersCreationForm.contractNo ||
    !ordersCreationForm.dataSource ||
    fetchingError;

  useEffect(() => {
    if (
      !contract ||
      !serviceSummary ||
      serviceSummary.availableOrderTypes.length === 0 ||
      ordersCreationForm.forms.length > 0
    )
      return;

    setInitialOrderFormWithContractDetails(ordersCreationForm.contractIdentifier, serviceSummary);
  }, [serviceSummary]);

  const addNextOrder = useCallback(() => {
    if (!serviceSummary) return;

    initializeNewOrderForm(ordersCreationForm.contractIdentifier, serviceSummary);
  }, [ordersCreationForm.contractIdentifier, serviceSummary]);

  return (
    <>
      <Container
        id={createServiceTileId(ordersCreationForm.contractIdentifier)}
        data-testid={`service-tile-${ordersCreationForm.contractNo}`}
      >
        <ServiceTileHeader
          serviceIndex={serviceIndex}
          contractNo={ordersCreationForm.contractNo}
          onIconClick={toggleModalOpened}
        />

        {incorrectDataError ? (
          <IncorrectDataError onClick={onChooseOtherServiceClick} />
        ) : (
          <Grid {...gridStyles} data-testid="service-tile-content">
            <ServiceDetails serviceSummary={serviceSummary} isLoading={isLoading} />

            {isLoading ? (
              <Placeholder {...orderTilesPlaceholderStyles} />
            ) : (
              contract &&
              serviceSummary && (
                <>
                  <OrderTiles
                    contract={contract}
                    availableOrderTypes={serviceSummary.availableOrderTypes}
                    contractIdentifier={ordersCreationForm.contractIdentifier}
                    ordersForms={ordersCreationForm.forms}
                  />

                  <Row {...rowWithMarginTop}>
                    <Col {...orderTileColStyles}>
                      <Button {...addButtonStyles} leftIcon={<IconAdd />} onClick={addNextOrder}>
                        {t('orderCreation:addNewOrder')}
                      </Button>
                    </Col>
                  </Row>
                </>
              )
            )}
          </Grid>
        )}
      </Container>

      <ConfirmationModal
        opened={modalOpened}
        onClose={toggleModalOpened}
        aria-labelledby="modal-heading"
        onConfirmClick={() => discardService(ordersCreationForm.contractIdentifier)}
        headerText={t('common:delete')}
        messageText={t('orderCreation:serviceDiscardingConfirmationMessage')}
      />
    </>
  );
};
